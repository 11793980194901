<template>
	<div class="mapboxGl">
        <div v-if="isMap" ref="CurrentEstimateMap" class="CurrentEstimateMap"></div>
	</div>
</template>
<script>
import Marker from '../../assets/images/Marker.png';
import Marker1 from '../../assets/images/Marker1.png';
    const mapboxgl = require("mapbox-gl");
	export default { 
		name: 'mapboxGl',
        props:{
            isMap: Boolean,
            currList: {
                type: Array,
                default: () => []
            },
        },
		data() {
			return {
                map: null,
                markers:[],
                center:[],
                PointArr:[],
                index: 0,
                spot: false,
                zIndex: 10,
                spotObj: {
                    el0: null,
                    el1: null,
                    el2: null,
                    el3: null,
                    el4: null,
                    el5: null,
                    el6: null,
                    el7: null,
                    el8: null,
                    el9: null,
                },
			}
		},
        watch:{
            isMap(val) {
                if(val) {
                    this.$nextTick(() => {
                        if(!this.spot) this.initmap()
                    })
                } else {
                    if(this.map) {
                        this.map.remove()
                        this.spot = false;
                    }
                }
            },
            currList(val) {
  
            }
        },
		created() {
            if(this.isMap) {
                this.$nextTick(() => {
                    if(!this.spot) this.initmap()
                })
            } else {
                if(this.map) {
                    this.map.remove()
                }
                
            }
		},  
		methods: {
            initmap(item, indexs, spot) {
                this.spot = spot;
                let TemCurrList = JSON.parse(JSON.stringify(this.currList));
                this.PointArr = [];
                TemCurrList.forEach((res, index) => {
                    if(!item && res.state == '2' && res.currState == '2') {
                        this.center = [res.blng, res.blat];
                        this.index = index;
                    } else if(item) {
                        this.center = [item.blng, item.blat];
                        this.index = indexs;
                    }
                    let obj = {};
                    obj.lng = res.blng;
                    obj.lat = res.blat;
                    obj.state = res.state;
                    obj.currState = res.currState;
                    obj.location = res.location;
                    this.PointArr.push(obj)
                });
                let style = this.lang == 'en_us'?'mapbox://styles/greyrayger/ckymurxf61gkl15qonsp3h7jf':'mapbox://styles/greyrayger/ckymurxf61gkl15qonsp3h7jf';
                mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JleXJheWdlciIsImEiOiJja2h6emV5NzQwcDkyMnBwNXUxNWhzMTdmIn0.C7iODszYx7MjjsvLlAtmog';
                let map = new mapboxgl.Map({
                    container: this.$refs.CurrentEstimateMap,
                    style: style,
                    center: this.center.length? this.center:[150, 30],
                    zoom: 1.5,
                    minZoom: 1.5,
                    trackResize: true,
                    pitchWithRotate: false,
                    dragRotate: false,
                });
                map.touchZoomRotate.disableRotation();
                this.map = map;
                this.deleteMarkers()
                let that = this;
			    map.on('load', () => {
                    let coordinates = [];
                    this.PointArr.forEach((res, index) => {
                        let popup;
                        this.spotObj['el' + index] = document.createElement('div');
                        this.spotObj['el' + index].innerText = (index + 1)
                        if(res.state == '2' && res.currState == '2') {
                            this.spotObj['el' + index].className = 'markerImg currMarkerImg';
                        } else if(res.state == '2' && res.currState == '1') {
                            this.spotObj['el' + index].className = 'markerImg isCurr';
                        } else {
                            this.spotObj['el' + index].className = 'markerImg';
                        };
                        if(index == this.index) {
                            this.zIndex  = this.zIndex + 1;
                            this.spotObj['el' + index].style.zIndex = this.zIndex;
                        };
                        let marker = new mapboxgl.Marker({
                            element: this.spotObj['el' + index],
                            offset: [0, 0]
                        }).setLngLat([Number(res.lng), Number(res.lat)]).addTo(that.map);
                        that.markers.push(marker);
                        this.spotObj['el' + index].addEventListener('click', function() {
                            map.panTo([Number(res.lng), (Number(res.lat) + 0.03)])
                            setTimeout(()=> {
                                map.zoomTo(10)
                            },500)
                        });
                        this.spotObj['el' + index].addEventListener('mouseenter', function() {
                                let markerHeight = 50, markerRadius = 10, linearOffset = 25;
                                let popupOffsets = {
                                    'top': [0, 0],
                                    'top-left': [0,0],
                                    'top-right': [0,0],
                                    'bottom': [0, -20],
                                    'bottom-left': [0, (markerHeight - markerRadius + linearOffset) * -1],
                                    'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                                    'left': [markerRadius, (markerHeight - markerRadius) * -1],
                                    'right': [-markerRadius, (markerHeight - markerRadius) * -1]
                                };
                                let html = `<div class="boxsss">
                                                <div>
                                                    <p>${res.location}</p>
                                                </div>
                                            </div>`
                                setTimeout(() => {
                                    popup = new mapboxgl.Popup(
                                        {
                                            offset: popupOffsets, 
                                            className: 'my-class',
                                            closeButton: false
                                        }
                                    ).setLngLat([Number(res.lng), Number(res.lat)]).setHTML(html).addTo(map);
                                },0)
                        });
                        this.spotObj['el' + index].addEventListener('mouseleave', function() {
                            popup.remove();
                        });
                        let arr = [res.lng, res.lat];
                        arr.push()
                        coordinates.push(arr)
                    })
                    let svgXML =
                        `<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"> 
                            <path d="M529.6128 512L239.9232 222.4128 384.7168 77.5168 819.2 512 384.7168 946.4832 239.9232 801.5872z" p-id="9085" fill="#ffffff"></path> 
                        </svg>`
                    let svgBase64 = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgXML)));
                    let arrowIcon = new Image(20, 20)
                    arrowIcon.src = svgBase64
                    arrowIcon.onload = function() {
                        map.addImage('arrowIcon', arrowIcon)
                    }
                    // 线
                    map.addLayer({
                        'id': 'lineOne',
                        'type': 'line',
                        'source': {
                            'type': 'geojson',
                            'data': {
                                'type': 'FeatureCollection',
                                'features': [{
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'LineString',
                                        'coordinates': that.Transformation(coordinates)
                                    }
                                }]
                            } //轨迹geojson格式数据
                        },
                        'paint': {
                            'line-width': 2,
                            'line-opacity': 1,
                            'line-color': '#ffffff',
                            'line-dasharray': [1, 5]
                        }
                    });
                    // 箭头
                    map.addLayer({
                        'id': 'arrowLayer',
                        'type': 'symbol',
                        'source': {
                            'type': 'geojson',
                            'data': {
                                'type': 'FeatureCollection',
                                'features': [{
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'LineString',
                                        'coordinates': that.Transformation(coordinates)
                                    }
                                }]
                            }
                        },
                        'layout': {
                            'symbol-placement': 'line',
                            'symbol-spacing': 20, // 图标间隔，默认为250
                            'icon-image': 'arrowIcon', //箭头图标
                            'icon-size': 0.6,
                        },
                        'paint': {
                            'icon-color': '#eb4b4b',
                        }
                    });
			    });
            },
            Transformation (arr) {
                let lstLonDiff = [];
                for (let i = 0; i < arr.length - 1; i++) {
                    let detLon = arr[i + 1][0] - arr[i][0];
                    if (Math.abs(detLon) > 180) {
                        if (detLon > 0) {
                            detLon -= 360;
                        }
                        else {
                            detLon += 360;
                        }
                    }
                    lstLonDiff.push(detLon);
                }
                if (arr[0][0] < 0)
                    arr[0][0] += 360;
                for (let i = 0; i < arr.length - 1; i++) {
                    arr[i + 1][0] = arr[i][0] + lstLonDiff[i];
                }
                return arr
            },
            PointArrFun() {
                this.PointArr.forEach((res, index) => {
                    if(index == this.index) {
                        this.zIndex  = this.zIndex + 1;
                        this.spotObj['el' + this.index].style.zIndex = this.zIndex;
                    }
                })
            },
            moveSpot(direction) {
                if(direction == 'left' && this.index != 0) {
                    this.index--;
                    this.PointArrFun();
                } else if(direction == 'right' && this.index < this.PointArr.length - 1) {
                    this.index++;
                    this.PointArrFun();
                } else {
                    this.$notify({
                        title: direction == 'left'? '当前为第一个点，暂时无法移动':'当前为最后一个点，暂时无法移动',
                        type: "error",
                        offset: 100,
                    });
                    return;
                }
                this.map.zoomTo(1.5);
                setTimeout(()=> {
                    this.map.panTo([Number(this.PointArr[this.index].lng), (Number(this.PointArr[this.index].lat) + 0.03)])
                    setTimeout(()=> {
                        this.map.zoomTo(10)
                    },1000)
                },1000)
            },
            deleteMarkers() {
                for (let i = 0; i < this.markers.length; i++) {
                    this.markers[i].remove();
                }
                this.markers = [];
            },
            MapImg() {
                this.isMap = true;
                this.$nextTick(() => {
                    this.initmap()
                })
            },
            BackImg() {
                this.isMap = false;
                this.map.remove()
            }
		},
		mounted: function() {
            window.addEventListener('resize', () => {
                this.map.resize();
            },false);
		},
        filters: {
            TimeInit(time) {
                // 21/4/2020
                let str = time.split(' ')[0];
				return str.split('-')[2] + '/' + str.split('-')[0] + '/' + str.split('-')[1]
			}
        }
	}
</script>

<style scoped lang="scss">
@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css");
    .mapboxGl{
        width: 100%;
        height: 100%;
        font-family: FontAwesome,Arial,Helvetica,sans-serif;
        background-color: #ffffff;
        position: relative;
        .MapImg{
            position: absolute;
            top: 12px;
            right: 0;
            cursor: pointer;
        }
        .BackImg{
            position: absolute;
            top: 12px;
            left: 5px;
            cursor: pointer;
            z-index: 9999;
        }
        /deep/.CurrentEstimateMap{
            width: 100%;
            height: 100%;
            outline: none !important;
            &:focus{
                outline: none !important;
            }
            .mapboxgl-canary:focus{
                outline: none !important;
            }
            .mapboxgl-canvas-container:focus{
                outline: none !important;
            }
            .mapboxgl-canvas-container{
                .mapboxgl-canvas:focus{
                    outline: none !important;
                }
            }
            .mapboxgl-control-container{
                display: none;
            }
            .markerImgIndex{
                z-index: 999;
            }
            .markerImg{
                width: 23px;
                height: 23px;
                line-height: 23px;
                text-align: center;
                font-size: 16px;
                color: #FFF;
                border: 2px solid #cccccc;
                border-radius: 50%;
                background-color: #cccccc;
                box-shadow: 0px 0.5px 5px #cccccc;
                // color: #132569;
                // border: 2px solid #132569;
                // border-radius: 50%;
                // background-color: #fff;
                // box-shadow: 0px 0.5px 5px #132569;
            }
            .currMarkerImg{
                color: #fff !important;
                background-color: #299BE4 !important;
                border: 2px solid #299BE4 !important;
            }
            .isCurr{
                background: #fff;
                border: 2px solid #132569;
                color: #132569 !important;
            }
            .my-class{
                width: auto;
                max-width: 240px;
                min-width: none !important;
                height: auto;
                box-shadow:  rgba(0,0,0,.2) 0 1px 4px;
                border-radius: 10px;
                .mapboxgl-popup-tip{
                    display: none;
                }
                .mapboxgl-popup-content{
                    border-radius: 10px;
                    text-align: center;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 5px 10px;
                    p{
                        text-align: center;
                        color: #6a6a6a;
                        margin: 0;
                        word-wrap: break-word;
                        white-space: normal;
                        word-break: break-all;
                    }
                }
            }
            .boxsss{
                width: 100%;
                height: 100%;
                background: #FFFFFF;
                border-radius: 10px;
                box-sizing: border-box;
            }
        }
    }
</style>


